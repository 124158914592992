import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import {Helmet} from "react-helmet-async";

import {
    Alert,
    Autocomplete,
    autocompleteClasses,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Divider as MuiDivider,
    Grid,
    ListSubheader,
    Paper,
    Popper,
    Snackbar,
    TextField,
    Typography,
    useMediaQuery
} from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {spacing} from "@mui/system";
import {VariableSizeList} from 'react-window';
import variants from "../../theme/variants";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {WEEK_DAYS} from "../../utils/constants";
import moment from 'moment';
import {getWeekMenuRepository, updateWeekMenuRepository} from "../../data/repository/menu";
import {useNavigate, useParams} from "react-router-dom";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ActivityButton from "../../components/unit/ActivityButton";
import {useTheme} from "@mui/material/styles";
import {getGroceryListRepository} from "../../data/repository/grocery-list";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

function renderRow(props) {
    const {data, index, style} = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: style.top + 8,
    };

    if (dataSet.hasOwnProperty('group')) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
                {dataSet.group}
            </ListSubheader>
        );
    }

    return (
        <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
            {dataSet[1].label}
        </Typography>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});


const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const {children, ...other} = props;
    const itemData = [];
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });

    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (child.hasOwnProperty('group')) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * 8}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

function MenuDetail() {

    const {isoWeek, isoWeekYear} = useParams()

    const menuStartDate = moment().isoWeekYear(isoWeekYear).isoWeek(isoWeek).startOf('isoWeek');

    const menuEndDate = moment(menuStartDate).endOf('isoWeek');
    const navigate = useNavigate()

    function CalendarColumn({meals, day, handleChangeInput}) {

        return (
            <div style={{flexDirection: 'column', width: '100%', display: 'flex'}}>
                {meals?.map((meal, mealIndex) => {
                    return (
                        <div key={mealIndex} style={{border: '1px solid grey', minWidth: 250}}>
                            {meal.selectedRecipes.map((selectedDishes, dishType) => {
                                return (
                                    <div key={`${mealIndex}-${dishType}`} style={{
                                        borderRadius: 4,
                                        margin: 4,
                                        marginBottom: 10,
                                        padding: 4,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        backgroundColor: (mealIndex === 0) ? '#F7972F' : '#00B4FF'
                                    }}>
                                        <span style={{
                                            fontWeight: 'bold',
                                            color: 'white'
                                        }}>{selectedDishes.dishType.toUpperCase()}</span>
                                        {selectedDishes.recipeIds.map((dish, dishIndex) => {

                                            return (
                                                <div key={`${day}-${mealIndex}-${dishType}-${dishIndex}`}
                                                     style={{flexDirection: 'row', display: 'flex', marginTop: 8, marginBottom: 2}}>
                                                    <Autocomplete
                                                        disablePortal
                                                        disableListWrap
                                                        PopperComponent={StyledPopper}
                                                        renderOption={(props, option) => [props, option]}
                                                        ListboxComponent={ListboxComponent}
                                                        isOptionEqualToValue={(option, value) => {
                                                            return option.id === value
                                                        }}
                                                        getOptionLabel={(option) => {
                                                            if (option.label) return option.label
                                                            return recipes.find((recipe) => recipe.id === option)?.label
                                                        }}
                                                        value={dish}
                                                        onChange={(event, newValue) => {
                                                            handleChangeInput(day, mealIndex, dishType, dishIndex, newValue?.id)
                                                        }}
                                                        key={`${day}-${mealIndex}-${dishType}-${dishIndex}`}
                                                        options={recipes}
                                                        sx={{width: '100%', backgroundColor: 'white'}}
                                                        renderInput={(params) => <TextField {...params}/>}
                                                    />
                                                    <a target={'_blank'} href={dish ? `/dashboard/recipes/${dish}` : null} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 10, cursor: 'pointer'}}>
                                                        <OpenInNewIcon style={{color: "white"}}/>
                                                    </a>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        )
    }

    function CalendarContainer({data, isLoading, handleChangeInput}) {

        return (
            <>
                {isLoading ? <div>Cargando...</div> :
                    <div style={{
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        display: 'flex'
                    }}>
                        {data.map((meals, index) => {
                            return (
                                <CalendarColumn day={index} handleChangeInput={handleChangeInput} key={index} meals={meals}/>
                            )
                        })}
                    </div>
                }
            </>
        )
    }

    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [data, setData] = useState([]);
    const [recipes, setRecipes] = useState([]);
    const [snackbar, setSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarText, setSnackbarText] = useState("");
    const [showLocalStorageButton, setShowLocalStorageButton] = useState(false);

    const getWeekMenu = () => {
        setIsLoading(true);

        getWeekMenuRepository(menuStartDate)
            .then((response) => {
                console.log(response[0], 'esto es la data')
                setData(response[0]);
                if (response[0][0][0].selectedRecipes[0].recipeIds[0] && !localStorage.getItem(`${isoWeek}-${isoWeekYear}-saved-menu`)) {
                    setShowLocalStorageButton(false);
                } else {
                    setShowLocalStorageButton(true);
                }
                setRecipes(response[1]);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            })
    }

    useEffect(() => {
        getWeekMenu();
    }, []);

    const goToWeek = (newDate) => {
        navigate(`/dashboard/menu/${newDate.isoWeek()}/${newDate.isoWeekYear()}`)
        window.location.reload()
    }

    const handleRecipeChange = (day, mealIndex, dishType, dishIndex, value) => {

        let newValue = [...data];

        newValue[day][mealIndex].selectedRecipes[dishType].recipeIds[dishIndex] = value

        setData(newValue);
    };

    const handleSaveLocalStorage = () => {
        setIsUpdating(true)
        let stringifyData = JSON.stringify(data);
        console.log(data, 'esta es la data')
        localStorage.setItem(`${isoWeek}-${isoWeekYear}-saved-menu`, stringifyData);
        setIsUpdating(false)
        setSnackbarSeverity("success");
        setSnackbarText('Se ha guardado de forma local correctamente');
        setSnackbar(true);

    }

    const handleUpdate = () => {
        setIsUpdating(true)
        updateWeekMenuRepository(menuStartDate, data)
            .then((response) => {
                console.log(response);
                setSnackbarSeverity("success");
                setSnackbarText('Se ha actualizado correctamente');
                setSnackbar(true);
                localStorage.removeItem(`${isoWeek}-${isoWeekYear}-saved-menu`);
                setIsUpdating(false);
            })
            .catch((error) => {
                setSnackbarSeverity("error");
                setSnackbarText(error);
                setSnackbar(true);
                setIsUpdating(false)
            })
    }

    const getGroceryList = () => {
        setIsUpdating(true)
        getGroceryListRepository(isoWeek, isoWeekYear)
            .then(() => {
                setIsUpdating(false);
            })
            .catch(error => {
                setSnackbarSeverity("error");
                setSnackbarText(error);
                setSnackbar(true);
                setIsUpdating(false)
            })
    }

    return (
        <React.Fragment>
            <Helmet title="Detalle semanal"/>

            <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Menú semanal
                    </Typography>

                    <Typography variant="h5" gutterBottom display="inline">
                        Configura las <span style={{color: '#F7972F'}}>comidas</span> y las <span
                        style={{color: '#00B4FF'}}>cenas</span> de la semana
                    </Typography>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', gap: 12, alignItems: 'flex-end' }}>
                    <div style={{display: 'flex', flexDirection: 'row', gap: 12}} >
                        {showLocalStorageButton && <div>
                            <ActivityButton title={'Guardar como borrador'} variant='outlined' onClick={handleSaveLocalStorage}
                                            isUpdating={isUpdating}/>
                        </div>}
                        <div>
                            <ActivityButton title={'Guardar menú'} onClick={handleUpdate} isUpdating={isUpdating} />
                        </div>
                    </div>
                    <div>
                        <ActivityButton title={'Ver lista de la compra'} variant='outlined' onClick={getGroceryList} isUpdating={isUpdating} />
                    </div>
                </div>

            </div>

            <Divider my={6}/>

            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card mb={6}>
                        <CardContent p={6}>

                            <Paper>
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <div style={{height: 42, width: 80, display: 'flex', flexDirection: 'row'}}>
                                        <div
                                            onClick={() => goToWeek(moment(menuStartDate).subtract(1, 'weeks'))}
                                            style={{
                                                height: '100%',
                                                width: '50%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: variants[0].palette.secondary.main
                                            }}>
                                            <KeyboardArrowLeftIcon style={{color: 'white'}}/>
                                        </div>
                                        <div
                                            onClick={() => goToWeek(moment(menuStartDate).add(1, 'weeks'))}
                                            style={{
                                                height: '100%',
                                                width: '50%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: variants[0].palette.secondary.main
                                            }}>
                                            <ChevronRightIcon style={{color: 'white'}}/>
                                        </div>
                                    </div>
                                    <h1> Semana
                                        del {moment(menuStartDate).format('DD-MM')} al {moment(menuEndDate).format('DD-MM')}</h1>
                                    <div/>
                                </div>
                                <div style={{overflowX: 'auto'}}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        paddingBottom: 0,
                                    }}>
                                        {WEEK_DAYS.map(((item, index) => {
                                            return (
                                                <div key={index} style={{
                                                    display: 'flex',
                                                    flex: 1,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    border: '1px solid grey',
                                                    minWidth: 250
                                                }}>
                                                    <p style={{fontSize: 14}}>{WEEK_DAYS[index].label.toUpperCase().slice(0, 3)}</p>
                                                </div>
                                            )
                                        }))}
                                    </div>

                                    <CalendarContainer handleChangeInput={handleRecipeChange} data={data} isLoading={isLoading}/>

                                </div>
                            </Paper>

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Snackbar open={snackbar} autoHideDuration={6000} onClose={() => setSnackbar(false)}>
                <Alert onClose={() => setSnackbar(false)} severity={snackbarSeverity} sx={{width: '100%'}}>
                    {snackbarText}
                </Alert>
            </Snackbar>

        </React.Fragment>
    );
}

export default MenuDetail;
